.nav-header {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    height: 15vh;
  }
  .nav-menu {
    list-style-type: none;
    display: flex;
    margin-left: -40px;
  }
  
  .nav-link {
    text-decoration: none;
    margin: 10px;
    /* color: white; */
    font-family: 'Roboto';
  }
  
  .blog-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .blog-title {
    font-family: 'Roboto';
    color: white;
    font-size: 16px;
  }
  .sub-title {
    color: white;
    font-family: 'Roboto';
    margin-top: 0px;
  }
  
  li {
    list-style-type: none;
    text-decoration: none;
    margin: 10px;
    color: white;
    font-family: 'Roboto';
    cursor: pointer;
  }
  
  @media screen and (min-width: 575px) {
    .blog-title {
      font-family: 'Roboto';
      color: white;
      font-size: 34px;
    }
  }
  .becomedeveloper{
    background-color:#696969;
    padding: 10px;
    border-radius: 18px;
    display: flex;
    
  }
  .icon{
    padding-left: 8px;
  }

  .names{
    padding-top: 4px;
    font-size: 25px;
    font-weight: 400;

  }

  ul {
    list-style-type:none;
  }
  
  a {
    position: relative;
    color:white;
    text-decoration: none;
  }
  
  a:visited {
    color: white;
    text-decoration:none;
  }

  a:hover {
    color:white;
    text-decoration:none;
  }
  
  a:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -2px;
    left: 0;
    background-color:red;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

  a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  .options{
    
    padding-top: 15px;
    margin-top: 15px;
    font-size: 22px;
  }
  .pesto{
    padding-right: 280px;
    
    font-size: 40px;
  }
  .becomedeveloper{
    margin-top: 10px;
  }