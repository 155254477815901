.para{
    text-align: center;
}
.maindiv9{
    background-color:dimgray;
    display: flex;
    padding: 20px;
    flex-direction: column;
}
.div10{
    display: flex;
    
    justify-content:space-around;
    margin: 10px;
    background-color:#DDDDDD;
    border-radius: 20px;
    padding: 10px;
}

.userimg{
    height: 200px;
    width: 140px;
    border-radius: 20px;
}
.time{
    color: brown;
    font-size: 14px;
    font-weight: 500;
}
.heading33{
    color: black;
    font-family: serif;
    font-size: 20px;
}
.guide{
    background-color:#0e1117;
    padding: 7px;
    border-radius: 10px;
    width: 70px;
    color: white;
}

.ul{
   

}
.div87{
    display: flex;
}
.divun{
    display: flex;
    flex-direction: row;
}